import styled, { css } from 'styled-components'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxM,
  MediaMaxS,
} from '@/5_shared/styles'
import { BannerDesktop, BannerMobile } from '@/5_shared/assets'

export const RootBorder = styled.div`
  padding: 4px;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    ${defaultColorPalette.lightAccentPink600} 0%,
    ${defaultColorPalette.lightAccentOrange500} 92.71%
  );

  ${MediaMaxM`
    border-top-right-radius: 64px;
    border-bottom-right-radius: 40px;
  `}

  ${MediaMaxS`
    border-top-right-radius: 80px;
    border-bottom-right-radius: 40px;
  `}
`

export const Root = styled.div`
  border-radius: 8px;
  padding: 20px 64px 20px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  position: relative;
  flex-wrap: wrap;

  box-shadow: 0 0 12px ${defaultColorPalette.darkSurfaceSecondary} inset;
  background: linear-gradient(
    135deg,
    ${defaultColorPalette.darkSurfaceSecondary} 0%,
    ${defaultColorPalette.darkSurfaceSecondary} 30%,
    ${defaultColorPalette.lightAccentPink600} 32%,
    ${defaultColorPalette.lightAccentOrange500} 92.71%
  );

  ${MediaMaxM`
    flex-direction: column;
    padding: 24px;
    border-top-right-radius: 64px;
    border-bottom-right-radius: 40px;
  `}

  ${MediaMaxS`
    padding: 16px;
    align-items: flex-start;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 40px;
  `}
`

export const Bonus = styled.div`
  font-family: 'Luckiest Guy';
  font-size: 38px;
  letter-spacing: 1.52px;
  color: ${defaultColorPalette.white};
  line-height: 38px;
  margin-bottom: -12px;
  text-shadow:
    -1.5px -1.5px 0 ${defaultColorPalette.lightTypeMedium},
    1.5px -1.5px 0 ${defaultColorPalette.lightTypeMedium},
    -1.5px 1.5px 0 ${defaultColorPalette.lightTypeMedium},
    1.5px 1.5px 0 ${defaultColorPalette.lightTypeMedium};
`

export const TermsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 28px;
  flex-wrap: wrap;

  ${MediaMaxS`
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  `}
`

const tems = css`
  color: ${defaultColorPalette.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-shadow:
    -0.75px -0.75px 0 ${defaultColorPalette.lightTypeMedium},
    0.75px -0.75px 0 ${defaultColorPalette.lightTypeMedium},
    -0.75px 0.75px 0 ${defaultColorPalette.lightTypeMedium},
    0.75px 0.75px 0 ${defaultColorPalette.lightTypeMedium};

  ${MediaMaxM`
    width: auto;
  `}
`

export const Terms1 = styled.div`
  width: 255px;
  ${tems}

  ${MediaMaxS`
    padding-right: 70px;
  `}
`

export const Terms2 = styled.div`
  width: 280px;
  ${tems}

  ${MediaMaxS`
    padding-right: 30px;
  `}
`

export const Image = styled.div`
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${BannerDesktop});
  margin-bottom: 36px;
  cursor: pointer;
  max-width: 860px;
  max-height: 100px;
  ${MediaMaxS`
    height: 124px;
    background: url(${BannerMobile});
    background-repeat: no-repeat;
    background-size: cover;
  `}
`

export const CloseButton = styled.div`
  position: absolute;
  z-index: 2;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  cursor: pointer;
  right: -15px;
  top: -15px;
  background: linear-gradient(
    135deg,
    ${defaultColorPalette.lightAccentPink600} 0%,
    ${defaultColorPalette.lightAccentOrange500} 92.71%
  );
  transition: all ${defaultTransitionTiming.fastest}s ease;

  &:after,
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 11px;
    height: 2px;
    background-color: ${defaultColorPalette.white};
    right: 6.5px;
    top: 11.5px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:hover {
    box-shadow: -0.5px 0.5px 1px ${defaultColorPalette.darkNeutrals100} inset;
  }
`
