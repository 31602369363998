import React, { FC, ReactNode } from 'react'
import * as Styled from './styled'
import * as MainStyled from '@/1_pages/main/ui/styled'
import { AuthModal } from '@/2_widgets/auth-modal'
import { DashboardModal } from '@/2_widgets/dashboard-modal'
import { Header } from '@/2_widgets/header'
import { Footer } from '@/2_widgets/footer'
import { GamesSelector } from '@/2_widgets/games-selector'
import { Media } from '@/5_shared/ui'
import { Jackpots } from '@/2_widgets/jackpots'
import { useStore } from 'effector-react'
import { UserService } from '@/7_services/user'

interface MainLayoutProps {
  children: ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {
  const { isLoggedIn } = useStore(UserService.getStore())

  return (
    <Styled.Root>
      <Header />

      <>
        <Media mobile tablet>
          <Styled.MainLayoutMobile>
            <Styled.JackpotsMobile />
            <MainStyled.BonusBanner />
            <GamesSelector />
            <div>{children}</div>
          </Styled.MainLayoutMobile>
        </Media>

        <Media desktop>
          <Styled.MainLayoutDesktop>
            <Styled.MainSidebarWrapper>
              <Styled.LogoLink to={'/'}>
                <Styled.Logo />
              </Styled.LogoLink>

              <GamesSelector />
            </Styled.MainSidebarWrapper>

            <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>

            <Styled.SidebarWrapper>
              {!isLoggedIn && <Styled.LoginButton />}

              {isLoggedIn && <Styled.ProfileBlock />}

              <Jackpots />
            </Styled.SidebarWrapper>
          </Styled.MainLayoutDesktop>
        </Media>
      </>

      <Footer />

      <Styled.Modals>
        <AuthModal />

        <DashboardModal />
      </Styled.Modals>
    </Styled.Root>
  )
}

MainLayout.displayName = 'MainLayout'
