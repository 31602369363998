import styled from 'styled-components'
import { BonusBanner as BonusBannerUi } from '@/4_entities/bonus-banner'
import { MediaMaxL, MediaMaxM, MediaMaxXl } from '@/5_shared/styles'

export const Root = styled.div``

export const BonusBanner = styled(BonusBannerUi)`
  margin-bottom: 70px;
`
export const MainContentWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-left: 72px;
  padding-right: 36px;
  padding-top: 100px;

  ${MediaMaxXl`
    padding-left: 36px;
  `}

  ${MediaMaxL`
    padding-left: 36px;
  `}

  ${MediaMaxM`
    padding: 50px 20px;
  `}
`
