import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { $isBonusBannerShown } from '../model'
import { useStore } from 'effector-react'
import { AuthTab, openAuthModal, selectTab } from '@/2_widgets/auth-modal'

export const BonusBanner: FC = memo(() => {
  const isShown = useStore($isBonusBannerShown)

  if (!isShown) {
    return null
  }

  return (
    <Styled.Image
      onClick={() => {
        selectTab(AuthTab.SignUp)
        openAuthModal()
      }}
    />
    // <Styled.RootBorder className={className}>
    //   <Styled.Root>
    //     <Styled.Image />
    //   </Styled.Root>
    // </Styled.RootBorder>
  )
})

BonusBanner.displayName = 'BonusBanner'
