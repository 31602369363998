import { Api } from '@/5_shared/api'
import type { GameRequest } from './types'

const fetchGamesList = () => {
  return Api.post(
    {
      cmd: 'gamelist',
    },
    {},
    true
  )
}

const fetchGameById = ({ id, width, height, currencyId }: GameRequest) => {
  return Api.post(
    {
      cmd: 'startGame',
    },
    {
      game_id: id,
      currency_id: currencyId,
      width,
      height,
      time: Date.now(),
    }
  )
}

export const api = { fetchGamesList, fetchGameById }
